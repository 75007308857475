import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../languages/i18nextConf";

import { Link } from "react-router-dom";

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import st from "./styles.module.scss";

import { getInvoice } from "./_actions";

import bookingDispatcher from "../ClientBooking/_dispatcher";

const Reservations = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const reservations = useSelector((state) => state.clientProfile.reservations);

    const { setBookingScreen, setBookingData } = bookingDispatcher();

    const togglePopUp = (name, value) => {
        dispatch({
            type: "OPEN_POP_CLIENT",
            payload: { name, value },
        });
    };

    const setSelectedReservation = (value) => {
        dispatch({
            type: "SET_SELECTED_RESERVATION",
            payload: value,
        });
    };

    return (
        <div className={st.reservation_table_wrapper}>
            <table className={st.reservation_table}>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>{t("client_profile.reservations.departure")}</th>
                        <th>{t("client_profile.reservations.arrival")}</th>
                        <th>{t("client_profile.reservations.stay")}</th>
                        <th>{t("client_profile.reservations.status")}</th>
                        <th>{t("client_profile.reservations.photos")}</th>
                        <th>{t("client_profile.reservations.price")}</th>
                        <th>{t("client_profile.reservations.actions")}</th>
                    </tr>
                </thead>
                <tbody>
                    {reservations.length > 0 &&
                        reservations.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.departure_at}</td>
                                    <td>{item.arrival_at}</td>
                                    <td>
                                        {item.days} {item.days === 1 ? "Day" : "Days"}
                                    </td>
                                    <td>
                                        {item.status === "awaiting" &&
                                        (item.payment_status === "pending" ||
                                            item.latest_payment.payment_status === "pending") ? (
                                            <span className='box box_lightBlue'>Pending</span>
                                        ) : (
                                            <span className='box box_lightBlue'>Confirmed</span>
                                        )}
                                        {item.status === "active" && (
                                            <span className='box box_green'>{item.status}</span>
                                        )}
                                        {item.status === "done" && (
                                            <span className='box box_orange'>{item.status}</span>
                                        )}
                                        {item.status === "not_come" && (
                                            <span className='box box_yellow'>{item.status}</span>
                                        )}
                                        {item.status === "not_done" && (
                                            <span className='box box_ping'>{item.status}</span>
                                        )}
                                        {item.status === "declined" && (
                                            <span className='box box_red'>Canceled</span>
                                        )}
                                    </td>
                                    <td className={st.photos}>
                                        {item.vehicle_photos.data.length === 0 &&
                                            t("client_profile.reservations.no_photos")}
                                        <SimpleReactLightbox>
                                            <SRLWrapper>
                                                {item.vehicle_photos.data.map((img, i) => {
                                                    if (i < 2) {
                                                        return (
                                                            <a
                                                                href={img.original_url}
                                                                key={i}
                                                                style={{
                                                                    display:
                                                                        i > 1
                                                                            ? "none"
                                                                            : "inline-block",
                                                                }}
                                                            >
                                                                <img src={img.original_url} />
                                                            </a>
                                                        );
                                                    }
                                                })}
                                            </SRLWrapper>
                                            {item.vehicle_photos.data.length > 2 ? (
                                                <span
                                                    style={{
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    + {item.vehicle_photos.data.length - 2}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </SimpleReactLightbox>
                                    </td>
                                    <td>{item.order_total} CHF</td>
                                    <td>
                                        {item.payment_status === "paid" && (
                                            <>
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        getInvoice(item.id, dispatch);
                                                    }}
                                                >
                                                    PDF <i className='far fa-file-pdf'></i>
                                                </span>
                                            </>
                                        )}
                                        {(item.status === "awaiting" || item.status === "active") &&
                                            item.payment_status === "paid" &&
                                            item.latest_payment.payment_status === "paid" && (
                                                <button
                                                    className='mt-0 btn small btn-submit'
                                                    onClick={() => {
                                                        togglePopUp("showProfile", false);
                                                        togglePopUp("showEditReservation", true);
                                                        setSelectedReservation(item);
                                                    }}
                                                >
                                                    {t("client_profile.reservations.edit_2")}
                                                </button>
                                            )}
                                        {(item.status === "awaiting" || item.status === "active") &&
                                            (item.payment_status === "pending" ||
                                                item.latest_payment.payment_status ===
                                                    "pending") && (
                                                <Link
                                                    to='/reservation'
                                                    className='mt-0 btn small btn-submit'
                                                    onClick={() => {
                                                        togglePopUp("showProfile", false);
                                                        setBookingScreen("active_step", 2.9);
                                                        setBookingScreen("aditional_payment", true);

                                                        setBookingData({
                                                            reservation_id: item.id,
                                                        });
                                                    }}
                                                >
                                                    {t("client_profile.reservations.edit_2")}
                                                </Link>
                                            )}
                                        {item.status === "awaiting" && (
                                            <>
                                                <button
                                                    className='mt-0 ms-2 btn small btn-cancel'
                                                    onClick={() => {
                                                        togglePopUp("showProfile", false);
                                                        togglePopUp("cancelReservation", true);
                                                        setSelectedReservation(item);
                                                    }}
                                                >
                                                    {t("client_profile.reservations.cancel")}
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}

                    {reservations.length === 0 && (
                        <tr>
                            <td className='text-center' colSpan='8'>
                                {t("client_profile.reservations.no_results")}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Reservations;

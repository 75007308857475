import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../../languages/i18nextConf";

import { useSelector } from "react-redux";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";
import { MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { Select } from "formik-material-ui";
import FormHelperText from "@material-ui/core/FormHelperText";

import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";

import PreviewLogic from "./PreviewLogic";

import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import ClosePopup from "../../../../images/icons/close-popup-icon.svg";

import st from "./styles.module.scss";

const Preview = () => {
  const { t } = useTranslation();

  const show = useSelector((state) => state.singleReservation.showPreview);
  const userPermitions = useSelector(
    (state) => state.dashboardReducer.permitions
  );

  const {
    parkingsList,
    role,
    state,
    loading,
    openReservationPreview,
    deleteReservation,
    onSubmit,
    handleImageUpload,
    handleDeleteImage,
    valetsList,
    valetData,
    handleValetChange,
    changeStatus,
    changeStatusWithPayment,
    assignValet,
    parking_id,
    changeParkingId,
    note,
    changeNote,
    clientId,
  } = PreviewLogic();
  const { departure_valet, arrival_valet } = valetData;
  const {
    id,
    client,
    vehicle,
    departure_at,
    arrival_at,
    parking_name,
    order_total,
    flight_number,
    vehicle_photos,
    status,
    payment_status,
    days,
    client_id,
  } = state;

  const { first_name, last_name, company, email, phone } = client || {};

  const { brand, model, plate } = vehicle || {};

  return (
    <>
      <div className={"pop " + (show ? "show" : "")}>
        <div className={st.inner + " inner"}>
          <img
            className={st.close}
            src={ClosePopup}
            onClick={() => openReservationPreview(false)}
          />

          <h3>
            {t("reservations.preview.title")} {id}
          </h3>
          <div className={st.summary}>
            <Formik
              enableReinitialize
              initialValues={state}
              onSubmit={onSubmit}
            >
              {({ errors, touched, setFieldValue, setTouched }) => {
                return (
                  <Form>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <h3 className={st.sub_title}>
                          <Link to={"/dashboard/clients/" + client_id}>
                            {first_name} {last_name}
                          </Link>
                        </h3>
                        <p>
                          <i className="fas fa-car me-2"></i>
                          {brand} {model} "{plate}"
                          <br />
                          <i className="fas fa-globe-europe me-2"></i>
                          {flight_number ? flight_number : "none"}
                        </p>
                      </div>
                      <div className={st.company_data + " col-md-6"}>
                        <p>{company ? company : "none"}</p>
                        <p>{email ? email : "none"}</p>
                        <p>{phone ? phone : "none"}</p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <p>
                          <i className="fas fa-plane-departure me-2"></i>
                          {departure_at}
                        </p>
                        <p>
                          <i className="fas fa-plane-arrival me-2"></i>
                          {arrival_at}
                        </p>
                        <p>
                          <i
                            className="far fa-calendar-alt"
                            style={{ marginRight: "13px" }}
                          ></i>
                          <strong>
                            {days}
                            {days === 1 ? " day " : " days "}
                            {t("reservations.preview.stay")}
                          </strong>
                        </p>
                        <p>
                          <i
                            className="fas fa-parking_id"
                            style={{ marginRight: "14px" }}
                          ></i>

                          <FormControl
                            className="full-width select-input"
                            variant="outlined"
                            error={
                              touched["parking_id"] && !!errors["parking_id"]
                            }
                          >
                            <InputLabel>Parking: *</InputLabel>
                            <Field
                              component={Select}
                              type="text"
                              name="parking_id"
                              value={parking_id}
                              onChange={(e) => changeParkingId(e.target.value)}
                            >
                              {parkingsList.map((item) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                            {errors.parking_id && (
                              <FormHelperText>
                                {errors.parking_id}
                              </FormHelperText>
                            )}
                          </FormControl>
                          {/*                                               
                                                    {role === 3 && (
                                                        <>
                                                            {t("reservations.preview.parked")} :{" "}
                                                            <strong
                                                                style={{
                                                                    textTransform: "capitalize",
                                                                }}
                                                            >
                                                                {parking_name
                                                                    ? parking_name
                                                                    : t(
                                                                          "reservations.preview.unknown"
                                                                      )}
                                                            </strong>
                                                        </>
                                                    )} */}
                        </p>
                      </div>

                      <div className={st.price + " col-md-6"}>
                        <div>
                          {order_total}
                          <span>CHF</span>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 mb-3">
                      <div className="col-md-12">
                        <h3 className={st.sub_title}>
                          {t("reservations.preview.note")}
                        </h3>
                        <div className="input-border textarea mb-2">
                          <Field
                            name="note"
                            value={note}
                            onChange={(e) => changeNote(e.target.value)}
                            type="text"
                            multiline={true}
                            label={t("reservations.single.note") + " *"}
                            component={TextField}
                          ></Field>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-6">
                        {userPermitions["update_reservation"] && (
                          <button
                            type="submit"
                            className={st.btn + " btn btn-submit mt-0"}
                          >
                            {t("reservations.preview.submit")}
                          </button>
                        )}
                      </div>
                      <div className="col-6">
                        {status !== "declined" && (
                          <button
                            onClick={() =>
                              deleteReservation(
                                id,
                                first_name + " " + last_name
                              )
                            }
                            type="button"
                            className={
                              st.btn +
                              " " +
                              st.cancel_btn +
                              " mt-0 btn btn-danger"
                            }
                          >
                            {t("reservations.preview.declined")}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className={st.status_wrapper}>
                      <div>
                        <h3 className={st.sub_title + " mt-3 mb-1"}>
                          {t("reservations.preview.status")}
                        </h3>

                        {status === "awaiting" && (
                          <span className="box box_lightBlue">{status}</span>
                        )}
                        {status === "active" && (
                          <span className="box box_green">Arrived</span>
                        )}
                        {status === "done" && (
                          <span className="box box_orange">{status}</span>
                        )}
                        {status === "not_come" && (
                          <span className="box box_yellow">{status}</span>
                        )}
                        {status === "not_done" && (
                          <span className="box box_ping">{status}</span>
                        )}
                        {status === "declined" && (
                          <span className="box box_red">Canceled</span>
                        )}
                      </div>
                      <div>
                        <h3 className={st.sub_title + " mt-3 mb-1"}>
                          {t("reservations.preview.payment_status")}
                        </h3>
                        {payment_status === "paid" && (
                          <span className="box box_green">
                            {payment_status}
                          </span>
                        )}
                        {payment_status === "pending" && (
                          <span className="box box_lightBlue">
                            {payment_status}
                          </span>
                        )}
                        {payment_status === "admin-reservation" && (
                          <span className="box box_orange">
                            {payment_status}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <h3 className={st.sub_title + " mt-3 mb-1"}>
                        {t("reservations.preview.change_status_payment")}:
                      </h3>
                      <div className="col-md-3">
                        {payment_status === "paid" && (
                          <button
                            onClick={() =>
                              changeStatusWithPayment(status, "pending")
                            }
                            type="button"
                            className={
                              st.btn + " btn full-width btn-warning mt-2"
                            }
                          >
                            {t("reservations.preview.pending")}
                          </button>
                        )}
                        {payment_status === "pending" && (
                          <button
                            onClick={() =>
                              changeStatusWithPayment(status, "paid")
                            }
                            type="button"
                            className={
                              st.btn + " btn full-width btn-submit mt-2"
                            }
                          >
                            {t("reservations.preview.paid")}
                          </button>
                        )}
                      </div>
                    </div>
                    {status !== "done" && (
                      <div className="row">
                        <h3 className={st.sub_title + " mt-3 mb-1"}>
                          {t("reservations.preview.change_status")}:
                        </h3>
                        <div className="col-md-3">
                          {vehicle_photos.length > 0 &&
                            parking_id &&
                            status !== "active" && (
                              <button
                                onClick={() => changeStatus("active")}
                                type="button"
                                className={
                                  st.btn + " btn full-width btn-submit mt-2"
                                }
                              >
                                {t("reservations.preview.active")}
                              </button>
                            )}
                        </div>
                        <div className="col-md-3">
                          {status !== "not_come" && (
                            <button
                              onClick={() => changeStatus("not_come")}
                              type="button"
                              className={
                                st.btn + " btn full-width btn-warning mt-2"
                              }
                            >
                              {t("reservations.preview.not_come")}
                            </button>
                          )}
                        </div>

                        <div className="col-md-3">
                          {status === "active" && status !== "done" && (
                            <button
                              onClick={() => changeStatus("done")}
                              type="button"
                              className={
                                st.btn + " btn full-width btn btn-orange mt-2"
                              }
                            >
                              {t("reservations.preview.done")}
                            </button>
                          )}
                        </div>
                        <div className="col-md-3">
                          {status !== "not_done" && (
                            <button
                              onClick={() => changeStatus("not_done")}
                              type="button"
                              className={
                                st.btn + " btn full-width btn btn-purple mt-2"
                              }
                            >
                              {t("reservations.preview.not_done")}
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <h3 className={st.sub_title}>
                          {t("reservations.preview.pictures")}
                        </h3>
                        {userPermitions["update_reservation"] && (
                          <div className={st.image_holder}>
                            <p>
                              <i className="fas fa-image"></i>
                              {t("reservations.preview.upload")}
                            </p>
                            <input
                              accept="image/*"
                              multiple
                              type="file"
                              onChange={handleImageUpload}
                              id="image"
                              name="image"
                            />
                          </div>
                        )}
                        <div className={st.image_wrapper + " " + "mb-5"}>
                          <SimpleReactLightbox>
                            <SRLWrapper>
                              {vehicle_photos.map((item) => {
                                return (
                                  <div
                                    key={item.original_url}
                                    className={st.single_image}
                                  >
                                    {userPermitions["update_reservation"] && (
                                      <button
                                        onClick={() =>
                                          handleDeleteImage(item.uuid)
                                        }
                                        type="button"
                                        className="small btn btn-danger"
                                      >
                                        <i className="far fa-trash-alt"></i>
                                      </button>
                                    )}
                                    <a href={item.original_url}>
                                      <img
                                        className={st.image}
                                        src={item.original_url}
                                      />
                                    </a>
                                  </div>
                                );
                              })}
                            </SRLWrapper>
                          </SimpleReactLightbox>
                          {vehicle_photos.length === 0 && (
                            <p className="mb-4">
                              {t("reservations.preview.no_images")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="input-border mb-3">
                          <Field
                            disabled={role === 3}
                            name="departure_valet"
                            value={departure_valet}
                            className="autocomplete"
                            options={valetsList}
                            getOptionLabel={(option) => option.name}
                            component={Autocomplete}
                            onChange={
                              ((e) =>
                                setFieldValue(
                                  "departure_valet",
                                  e.target.value
                                ),
                              (event, value) =>
                                handleValetChange(
                                  event,
                                  value,
                                  "departure_valet"
                                ))
                            }
                            onBlur={() =>
                              setTouched({
                                ["departure_valet"]: true,
                              })
                            }
                            renderInput={(params) => {
                              return (
                                <MuiTextField
                                  label={t(
                                    "reservations.preview.departure_valet"
                                  )}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                  }}
                                  name="departure_valet"
                                  error={
                                    touched["departure_valet"] &&
                                    errors["departure_valet"]
                                  }
                                  helperText={
                                    touched["departure_valet"] &&
                                    errors["departure_valet"]
                                  }
                                />
                              );
                            }}
                          ></Field>
                        </div>
                      </div>
                      <div className="col-2">
                        {role !== 3 && departure_valet.id && parking_id && (
                          <button
                            onClick={() => {
                              assignValet("departure_valet");
                            }}
                            type="button"
                            className={st.btn + " btn btn-assign mt-0"}
                          >
                            {t("reservations.preview.assign")}
                          </button>
                        )}
                      </div>
                      <div className="col-4">
                        <div className="input-border mb-3">
                          <Field
                            disabled={role === 3}
                            name="arrival_valet"
                            value={arrival_valet}
                            className="autocomplete"
                            options={valetsList}
                            getOptionLabel={(option) => option.name}
                            component={Autocomplete}
                            onChange={
                              ((e) =>
                                setFieldValue("arrival_valet", e.target.value),
                              (event, value) =>
                                handleValetChange(
                                  event,
                                  value,
                                  "arrival_valet"
                                ))
                            }
                            onBlur={() =>
                              setTouched({
                                ["arrival_valet"]: true,
                              })
                            }
                            renderInput={(params) => {
                              return (
                                <MuiTextField
                                  label={t(
                                    "reservations.preview.arrival_valet"
                                  )}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                  }}
                                  name="arrival_valet"
                                  error={
                                    touched["arrival_valet"] &&
                                    errors["arrival_valet"]
                                  }
                                  helperText={
                                    touched["arrival_valet"] &&
                                    errors["arrival_valet"]
                                  }
                                />
                              );
                            }}
                          ></Field>
                        </div>
                      </div>
                      <div className="col-2">
                        {role !== 3 && arrival_valet.id && parking_id && (
                          <button
                            onClick={() => {
                              assignValet("arrival_valet");
                            }}
                            type="button"
                            className={st.btn + " btn btn-assign mt-0"}
                          >
                            {t("reservations.preview.assign")}
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <div className="row mt-2 mb-3">
              <div className="col-md-12">
                <p className={st.label}>
                  SMS with reservation details and contact information will be
                  sent to Client and Valet when Submit assignemet
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getReservationsCalendar, checkForPlaces } from "./_actions";

import moment from "moment-timezone";

const CalendarLogic = () => {
    const dispatch = useDispatch();
    const reservations = useSelector((state) => state.reservationCalendar.reservations);
    const selectedMonth = useSelector((state) => state.reservationCalendar.selectedMonth);
    const userPermitions = useSelector((state) => state.dashboardReducer.permitions);
    const freePlaces = useSelector((state) => state.reservationCalendar.freePlaces);

    const [reservationsList, setReservationsList] = useState([]);

    useEffect(() => {
        const currentDate = new Date();
        const date = "01-" + moment(currentDate).format("MM-YYYY");
        dispatch({
            type: "SET_MONTH_RESEVATION",
            payload: date,
        });
    }, []);

    const [currentMonth, setCurrentMonth] = useState([]);
    const [currentMonthEnd, setCurrentMonthEnd] = useState([]);
    useEffect(() => {
        if (selectedMonth) {
            const selectedMonthEnd = moment(selectedMonth, "DD-MM-YYYY")
                .add(1, "M")
                .startOf("month")
                .format("DD-MM-YYYY");
            getReservationsCalendar(selectedMonth, dispatch);

            const currentMonth = new Date(
                moment(selectedMonth, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD") +
                    "T" +
                    moment(selectedMonth, "DD-MM-YYYY HH:mm:ss")
                        .add(1, "minutes")
                        .format("HH:mm:ss")
            );

            setCurrentMonth(currentMonth);
            const currentMonthEnd = new Date(
                moment(selectedMonthEnd, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD") +
                    "T" +
                    moment(selectedMonthEnd, "DD-MM-YYYY HH:mm:ss")
                        .add(1, "minutes")
                        .format("HH:mm:ss")
            );
            setCurrentMonthEnd(currentMonthEnd);
        }
    }, [selectedMonth]);

    const formatDateStart = (date) => {
        return (
            moment(date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD") +
            "T" +
            moment(date, "DD-MM-YYYY HH:mm:ss").format("HH:mm:ss")
        );
    };
    const formatDateEnd = (date) => {
        return (
            moment(date, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD") +
            "T" +
            moment(date, "DD-MM-YYYY HH:mm:ss").add(1, "minutes").format("HH:mm:ss")
        );
    };
    useEffect(() => {
        if (reservations.length > 0) {
            const formatedEventsStart = reservations.map((item) => {
                const { status, reason } = item;
                const { first_name, last_name } = item.client || {};

                const startDate = new Date(formatDateStart(item.departure_at));
                const endDate = new Date(formatDateEnd(item.departure_at));

                if (
                    moment(startDate).isAfter(currentMonth) &&
                    moment(startDate).isBefore(currentMonthEnd) &&
                    item.status !== "declined"
                ) {
                    return {
                        status: status,
                        id: item.id,
                        start: startDate,
                        end: endDate,
                        title: first_name + " " + last_name,
                        allDay: false,
                        type: "start",
                        payment_status: item.payment_status,
                        arrival_valet: item.arrival_valet,
                        departure_valet: item.departure_valet,
                        parking_name: item.parking_name,
                        vehicle: item.vehicle,
                        flight_number: item.flight_number,
                    };
                }
            });
            const formatedEventsEnd = reservations.map((item) => {
                const { status, reason } = item;
                const { first_name, last_name } = item.client || {};

                const startDate = new Date(formatDateStart(item.arrival_at));
                const endDate = new Date(formatDateEnd(item.arrival_at));

                if (
                    moment(startDate).isAfter(currentMonth) &&
                    moment(startDate).isBefore(currentMonthEnd) &&
                    item.status !== "declined"
                ) {
                    return {
                        status: status,
                        id: item.id,
                        start: startDate,
                        end: endDate,
                        title: first_name + " " + last_name,
                        allDay: false,
                        type: "end",
                        payment_status: item.payment_status,
                        arrival_valet: item.arrival_valet,
                        departure_valet: item.departure_valet,
                        parking_name: item.parking_name,
                        vehicle: item.vehicle,
                        flight_number: item.flight_number,
                    };
                }
            });

            const start = formatedEventsStart.filter((item) => item !== undefined);
            const end = formatedEventsEnd.filter((item) => item !== undefined);

            setReservationsList([...start, ...end]);
        }
    }, [reservations]);

    const [dateOnNavigate, setDateOnNavigate] = useState("");
    const onNavigate = (date, view, type) => {
        setDateOnNavigate(dateOnNavigate);
        if (type === "NEXT" || type === "PREV") {
            dispatch({
                type: "SET_MONTH_RESEVATION",
                payload: "01-" + moment(date).format("MM-YYYY"),
            });
        }
        if (view === "day") {
            checkForPlaces(date, dispatch);
        } else {
            dispatch({
                type: "SET_FREE_PLACES",
                payload: [],
            });
        }
    };
    const onDrillDown = (date) => {
        checkForPlaces(date, dispatch);
    };
    const eventStyleGetter = (event, start, end, isSelected) => {
        const { status, type, id } = event;
        let color = "";
        if (status === "awaiting" && type === "start") {
            color = "#0e74ca"; // blue
            // } else if (status === "awaiting" && type === "end") {
        } else if (status === "active" && type === "start") {
            color = "#097200"; // green
        } else if (status === "done") {
            color = "#ff882a"; //orange
        } else if (status === "not_come") {
            color = "#ffc720"; //yellow
        } else if (status === "not_done") {
            color = "#d42aff"; // purple
        } else if (type === "end" || (type === "end" && type === "active")) {
            color = "red"; // red
        }

        let style = {
            backgroundColor: color,
            borderRadius: "0px",
            border: "none",
            padding: "3px 10px",
            opacity: 0.8,
            color: status === "not_come" ? "#000" : "#fff",
        };
        return { style: style };
    };

    const addReservation = (id = null) => {
        dispatch({
            type: "SHOW_EDIT_RESERVATION_POPUP",
            payload: { id, status: true },
        });
    };

    const onSelectReservation = (data, e) => {
        userPermitions["view_reservation"] &&
            dispatch({
                type: "SHOW_PREVIEW_RESERVATION_POPUP",
                payload: { id: data.id, status: true },
            });
    };

    return {
        onDrillDown,
        addReservation,
        reservationsList,
        onNavigate,
        eventStyleGetter,
        onSelectReservation,
        freePlaces,
    };
};

export default CalendarLogic;
